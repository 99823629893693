import React, { useState } from 'react';
import {  FileUploaderItem, FileUploaderDropContainer, Button, InlineLoading, InlineNotification, Dropdown } from '@carbon/react';
import axios from 'axios';
import './FileRun.css';

const FileRun = () => {

  const [javaFramework, setJavaFramework] = useState("Spring");
  const [files, setFiles] = useState([]);
  const [runbtn, setRunbtn] = useState("none");
  const [trx_urn, setTrxUrn] = useState("");
  const [errorMessage, setErrorMessage] = useState('');

  const handleDrop = (filesToAdd) => {
    // Add the new files to the existing files array
    setFiles(prevFiles => [...prevFiles, ...filesToAdd]);
  };

  const items = ['Spring', 'Struts']

  

  const checkStatus = async (trx_urn) => {
    try {
      const response = await axios.get(`/api/v1/openapi-spec/${trx_urn}/status`);

      if (response.data.status === 'SUCCESS') {
        console.log('File processing completed:', response.data);
        setRunbtn("download");
      }
      else if (response.data.status === 'FAILURE') {
        setErrorMessage("Failed")
        setRunbtn("none");
      } else {
        console.log('File processing status:', response.data.status);
        // If status is not 'OK', check again after a delay
        setTimeout(() => checkStatus(trx_urn), 2000); // Check every 2 seconds
      }
    } catch (error) {
      setRunbtn("none");
      setErrorMessage("Failed")
    }
  };

  const handleUpload = async () => {
    setErrorMessage("");
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    setRunbtn("running");

    try {
      const response = await axios.post(`/api/v1/openapi-spec/batch-specification?framework=${javaFramework}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully:', response.data);
      setTrxUrn(response.data.trx_urn);
      checkStatus(response.data.trx_urn);

    } catch (error) {
      console.error('Error uploading file:', error);
      setErrorMessage("Error uploading file");
      setRunbtn("none");
    }
  };


  const handleDownload = async () => {
    setRunbtn("downloading");
  
    try {
      const response = await axios.get(`/api/v1/openapi-spec/${trx_urn}/specification-result`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'result.zip'); // or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setRunbtn("none");
    } catch (error) {
      console.error('Error Downloading file:', error);
      setErrorMessage("Error Downloading file");
      setRunbtn("none");
    }
  };
  
  var button = "";

    if (files.length === 0){
      button = <Button
                disabled={true}
                kind="secondary"
                style={{padding: "1rem 3rem", margin: "1rem 0"}}
                size="lg"
              >
              Generate
              </Button>

    }else if (runbtn ==="none"){
      button = <Button
                onClick={handleUpload}
                kind="secondary"
                style={{padding: "1rem 3rem", margin: "1rem 0"}}
                size="lg"
              >
              Generate
              </Button>

    } else if (runbtn ==="running"){
        button = <InlineLoading description="Generating...."/>

    }else if (runbtn ==="download"){
      button = <Button
                onClick={handleDownload}
                kind="secondary"
                style={{padding: "1rem 3rem", margin: "1rem 0"}}
                size="lg"
              >
              Download
              </Button>

    } else if (runbtn ==="downloading"){
        button = <InlineLoading description="Downloading...."/>

    }

  return (
    <div className='main-container'>
      <div>
      <p className="cds--file--label">
        Upload files
      </p>
      <p className="cds--label-description">
        Max file size is 500KB. Supported file type are .java and .xml
      </p>
      <FileUploaderDropContainer
        accept={[
          '.java',
          '.xml'
        ]}
        innerRef={{
          current: '[Circular]'
        }}
        labelText="Drag and drop files here or click to upload"
        multiple
        name=""
        onAddFiles={(event, { addedFiles }) => handleDrop(addedFiles)}
        onChange={function noRefCheck(){}}
      />

      <Dropdown  
          id="default" titleText="Java Framework" 
          initialSelectedItem={items[0]} 
          size={"sm"}
          margin={"10px"}
          style={{"width":"20rem"}}
          items={items} 
          itemToString={item => item ? item : ''} 
          onChange={(item)=> setJavaFramework(item.selectedItem)}
      />
      
      {files.length > 0 && (
        <div style={{ marginTop: '1rem' }}>
          {files.map((file, index) => (
            <FileUploaderItem
              key={index}
              name={file.name}
              status="edit"
              onDelete={() => setFiles(files.filter((f) => f !== file))}
            />
          ))}
        </div>
      )}
      
    </div>
        
        <div className='output-container'>
        <p className="cds--file--label">
            Output
        </p>


        
        
        

        {button} <br />
       

        { errorMessage && <InlineNotification
          aria-label="closes notification"
          kind="error"
          onClose={()=>setErrorMessage("")}
          onCloseButtonClick={()=>setErrorMessage("")}
          statusIconDescription="notification"
          // subtitle="Subtitle text goes here"
          title={errorMessage}/>
        }
        
       
        
        
        </div>
    </div>
  );
};

export default FileRun;
