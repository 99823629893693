import React from "react";
import FuncSpecHome from "./func_spec/FuncSpecHome";
import OpenAPISpecHome from "./openapi_spec/OpenAPISpecHome";
import Home from "./Home";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";



function AppRouter() {

  return (
    <Router>
      <Routes>
        <Route
            exact path="/"
            element={<Home />}          
            />
        <Route
          path="/spec/func-spec"
          element={<FuncSpecHome/>}          
        />
        <Route
          path="/spec/openapi-spec"
          element={<OpenAPISpecHome/>}          
        />
        
      </Routes>
    </Router>
  );
}

export default AppRouter;