import "./App.css";
import AppRouter from "./components/router";
import Header from "./components/Header/Header";


function App() {

  return (
      <>
      <Header />
      <AppRouter />
      </>
      
  );
}
export default App;
