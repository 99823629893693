
import './OpenAPISpecHome.css';


import FileRun from './FileRun/FileRun';
import Playground from './Playground/Playground';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@carbon/react';

function OpenAPISpecHome() {
  
  return (
    <div className="App-code-windows">
      
      <div className='tab-container'>
        <Tabs>
            <TabList aria-label="List of tabs">
                <Tab>Playground</Tab>
                <Tab>File Execution</Tab>
            </TabList>
            <TabPanels>
                <TabPanel><Playground /></TabPanel>
                <TabPanel><FileRun /></TabPanel>
            </TabPanels>
        </Tabs>
        </div>

      
      </div>
  );
}
export default OpenAPISpecHome;
