import "./Home.css";

import { ClickableTile } from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div style={{ display: "flex" }}>
        <ClickableTile
          onClick={() => navigate("/spec/func-spec")}
          slug
          id="tile-click"
          renderIcon={ArrowRight}
          className="tile"
        >
          <h4>Functional Specification</h4>
          <p>
            Extract Functional Specification from the Java Code and Generate
            User stories.
          </p>
        </ClickableTile>

        <ClickableTile
         onClick={() => navigate("/spec/openapi-spec")}
          slug
          id="tile-click"
          renderIcon={ArrowRight}
          className="tile"
        >
          <h4>OpenAPI Specification</h4>
          <p style={{ font: "small" }}>
            Generate OpenAPI Specification from Java controller and model class.
          </p>
        </ClickableTile>
      </div>
    </>
  );
}
export default Home;
