import './Header.css';

function Header() {
    return (
        <header className="Header_root" >
            <a className="Header_name" href="/">
                <span className="Header_prefix">HCS GenAI Lab</span>
                &nbsp;AWS
            </a>
        </header>
    );
}

export default Header;