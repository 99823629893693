import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox"; 

import {Button, InlineLoading, ModalHeader, ModalBody, ComposedModal, Tooltip, Dropdown} from '@carbon/react';
import './Playground.scss';

import { useState} from "react";
import { jsPDF } from "jspdf";

import { Copy, Download, Checkmark, Information } from '@carbon/icons-react';

function Playground() {


    const [javaFramework, setJavaFramework] = useState("Spring")
    const [inputCode, setInputCode] = useState("")
    const [result, setResult] = useState()
    const [execution, setExecution] = useState(false)
    const [open, setOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    
    


    const startExecution = () =>{
        if (inputCode.length > 0){
            setExecution(true);
            setResult({'result':''})
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({'input': inputCode })
            };
            fetch(`/api/v1/openapi-spec/specification?framework=${javaFramework}`, requestOptions)
                .then((res) => res.json())
                .then((result) => {
                    console.log(result);
                    setResult(result);
                    setExecution(false);
                })
                .catch((error)=> {
                    if (error.response) {
                        setExecution(false);
                        setResult('Error Occurred');
                    } else { 
                        setExecution(false);
                        setResult('Timeout Error Occurred');
                    }
                })
        }
    }

    const handleDownload = () => {
        var doc = new jsPDF({format: 'a4', orientation: 'portrait', unit: 'mm', lineHeight: 2});
        doc.setFontSize(10);
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        let iterations = 1;
        const margin = 15;
        const defaultYJump = 5;
        var lines = doc.splitTextToSize(result?.result, 180);
        lines.forEach(line => {
            let posY = margin + defaultYJump * iterations++;
            if (posY > pageHeight - margin) {
                doc.addPage();
                iterations = 1;
                posY = margin + defaultYJump * iterations++;
            }
            doc.text(15, posY, line, {maxWidth: pageWidth-20});
        });
        doc.save(`genailab-output-${new Date().toISOString()}.pdf`);
    }

    const handleCopy = () => {
        try {
            setCopied(true);
            navigator.clipboard.writeText(result?.result);
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        } catch (err) {
            console.error(
                "Unable to copy to clipboard.",
                err
            );
        }
    }

 
    const items = ['Spring', 'Struts']
   console.log(javaFramework)
    
    return (
            
        <main className="Playground-content">
        <div className="Playground-loading">
            
            
            <p className="Playground-prompt-detail">Extract OpenAPI Specification from Java Application.</p>

            


            
            
            {typeof document === 'undefined'
                ? null
                :   
                    <ComposedModal  open={open} onClose={() => setOpen(false)}>
                        <ModalHeader title="Platform Execution Information" />
                        <ModalBody hasScrollingContent aria-label="Modal">
                            <pre style={{"whiteSpace":"pre-line"}}>
                                 {result ?  
                                 <div>
                                    <table class="table table-striped">
                                        
                                        <tbody>
                                            {result['platform-execution-info'] ? 
                                            <div>

                                                    <tr>
                                                        <td ><strong>Platform Name  </strong></td>
                                                        <td style={{'width': '5rem'}}></td>
                                                        <td>{result['platform-execution-info']['platform-name'].toUpperCase()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Input Tokens </strong></td>
                                                         <td style={{'width': '5rem'}}></td>
                                                        <td>{result['platform-execution-info']['input-tokens']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Output Tokens </strong></td>
                                                         <td style={{'width': '5rem'}}></td>
                                                        <td>{result['platform-execution-info']['output-tokens']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Cost </strong></td>
                                                         <td style={{'width': '5rem'}}></td>
                                                        <td>${result['platform-execution-info']['cost']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Response Time </strong></td>
                                                         <td style={{'width': '5rem'}}></td>
                                                        <td>{result['platform-execution-info']['response-time']}</td>
                                                    </tr>
                                                
                                                    <tr>
                                                        <td><strong>Model </strong></td>
                                                         <td style={{'width': '5rem'}}></td>
                                                        <td>{result['platform-execution-info']['model-id']}</td>
                                                    </tr>
                                            </div>
                                                
                                            : ""}
                                        </tbody>
                                    </table>
                                </div>: ""} 
                                 </pre> 
                        </ModalBody>
                       
                    </ComposedModal>
                }


            
            <Information className="Playground-actual-prompt-btn" kind="secondary" onClick={() => setOpen(true)}>
            </Information>

            {execution ? <InlineLoading status="active" iconDescription="Loading" className="Playground-loader" description="Generating" />:(result && (result!=='Error Occured' || result!=='Timeout Error Occured'))?<InlineLoading status="finished"  className="Playground-loader" description="Finished" />:<InlineLoading status="finished" className="Playground-loader" description="Available" />}
            
            {execution ? <Button className="Playground-execute-btn" kind="secondary" disabled="false">Generate</Button>:<Button kind="secondary" onClick={startExecution} className="Playground-execute-btn">Generate</Button>}
        </div> 

        <div className="Playground-container">
            <div className="Playground-infobar">
                <div className="Playground-input-title"><p>Input</p> 
                    <Dropdown  
                        id="default" titleText="Java Framework" 
                        initialSelectedItem={items[0]}
                        type="inline" 
                        size={"sm"}
                        margin={"10px"}
                        items={items} 
                        itemToString={item => item ? item : ''} 
                        onChange={(item)=> setJavaFramework(item.selectedItem)}
                    />
                </div>
                
                {/* <div className="Playground-prompt-id">{result ? result['platform-execution-info']['model-id'] : ""} </div> */}
                <div className="Playground-output-title">Output </div>
            </div> 
            
            
            <div className="Playground-editors">
                    <AceEditor
                    className="Playground-input-editor"
                    height="40rem"
                    width="95%"
                    value={inputCode}
                    mode="python"
                    theme="xcode"
                    fontSize=".85rem"
                    highlightActiveLine={true}
                    onChange={(value)=>{setInputCode(value)}}
                    gutterWidth={100}
                    setOptions={{
                        enableLiveAutocompletion: true,
                        showLineNumbers: true,
                        tabSize: 2,
                        wrap: true
                    }}
                    />
                
                
                    <AceEditor
                    className="Playground-output-editor"
                    height="40rem"
                    width="95%"
                    value={result?.result}
                    mode="python"
                    theme="xcode"
                    fontSize=".85rem"
                    highlightActiveLine={true}
                    setOptions={{
                        enableLiveAutocompletion: true,
                        showLineNumbers: true,
                        tabSize: 2,
                        copyWithEmptySelection: true,
                        wrap: true,
                        readOnly:true
                    }}
                    /> 
            </div> 
            
            
        </div>

        <div className="Playground-options">
            
            <div className="Playground-options-copy" >
                <Tooltip  align="left" label="Copy" >
                    <button className='button' onClick={handleCopy} >
                        {!copied ? <Copy aria-label="Copy"   /> : <Checkmark aria-label="Copied" />}
                    </button>
                </Tooltip>
            </div>
            <div className="Playground-options-download">
                <Tooltip  align="left" label="Download">
                    <button className='button'>
                        <Download aria-label="Download"  onClick={handleDownload} />
                    </button>
                </Tooltip>
            </div>
        </div>      

        </main>
    );
    
  
}

export default Playground;